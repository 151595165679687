
const urlMode = 'list'; // 'list' or 'default'

const hostname = window.location.hostname;

const domainParts = hostname.split('.');
let request_domain = hostname; // 默认是完整 hostname

if (domainParts.length > 2) {
  request_domain = domainParts.slice(-2).join('.');
}

const protocol = location.protocol === 'http:' ? 'http' : 'https';

export { urlMode };

export default [
  `${protocol}://api.${request_domain}`,
  'https://api.yepbit.pro',
  'https://api.yepbit.com',
  'https://api.yepbit.lol',
  'https://api.yepbit.me',
  'https://api.yepbit.net',
  'https://yep.fppitcfs.com',
]
